import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Question from "../components/question";
import { Link } from "gatsby";

const FaqPage = () => {

    const QA = ({ name, children }) => (
        <div className="flex flex-row text-black text-opacity-75 text-base xs:text-sm mt-1">
            <div className="font-bold mr-1">
                {name}:
            </div>
            <div className="flex flex-col w-full">
                {children}
            </div>
        </div>
    );

    const Section = ({ name, children }) => (
        <div>
            <div className="flex w-full bg-blue text-white px-2">
                {name}
            </div>
            <div className="px-2 py-1">
                {children}
            </div>
        </div>
    );

    return (
        <Layout>
            <SEO title="FAQ" />
            <div className="container mx-auto p-4">
                <div className="flex text-orange font-semibold text-5xl justify-start xs:text-3xl xs:justify-center">
                    FAQ's
                </div>
                <div className="flex justify-start xs:justify-center text-black text-opacity-50 text-base xs:text-sm">
                    You brought the Q's we're giving the A's.
                </div>
            </div>
            <div className="container mx-auto px-4">
                <Question title="6-Star Rating Scale? 🤔">
                    <QA name="Q">
                        What's up with the 6-star rating scale?
                    </QA>
                    <QA name="A">
                        Sometimes 5-stars just isn't enough. This is dedicated to Sian's invention of the 6 lime box rating system.
                    </QA>
                </Question>
                <Question title="Rating Categories? 🍣">
                    <QA name="Q">
                        What do all the rating categories mean?
                    </QA>
                    <QA name="A">
                        Here's a breakdown:
                        <div className="flex flex-col">
                            <Section name="Overall">
                                Although maybe expected to be simply a cumulative score of the other categories, 
                                the "Overall" score is actually based on gut feels and remberances. It's a number 
                                that's not just about the numbers.
                            </Section>
                            <Section name="Extra Ginger">
                                Our most important category, for a life without ginger is no life at all. 
                                That is where we push the boundaries of awkwardness to see just how much extra 
                                ginger we can get.
                            </Section>
                            <Section name="Price">
                                Straight forward, the lower the price the better! We standardized this by 
                                ordering a bowl with two types of fish and no upcharges.
                            </Section>
                            <Section name="Quantity">
                                A scale ranging from 1 meaning "wow, that was barely a snack", to 6 meaning 
                                "For multiple reasons, I need to get out of these pants".
                            </Section>
                            <Section name="Fish">
                                This score takes into account both the quality and quantity of fish (heads up that these basic bithes
                                usually go for tuna and salmon).
                            </Section>
                            <Section name="Rice">
                                How good was the rice? Was it the right amount of rice? Would I eat it plain? If the answers were,
                                "Perfect. Yes. All damn day." Then you've got yourself a 6 out of 6.
                            </Section>
                            <Section name="Toppings">
                                All about variety, more stars mean more (good) options!
                            </Section>
                            <Section name="Sauce">
                                Sauce variety and quality are most important here
                                with bonus points for creativity.
                            </Section>
                            <Section name="Eco-friendly">
                                A black and white category, either we feel they are trying to be eco-friendly or not. 
                                Nothing ruins the mood like forgetting to look out for our #1 lady, Mother Earth.
                            </Section>
                        </div>                 
                    </QA>
                </Question>
                <Question title="What is the cute lil fishy's name?">
                    <QA name="Q">
                        What is the cute lil fishy's name?
                    </QA>
                    <QA name="A">
                        Jorge.
                        <br></br>
                        <br></br>
                        jk their name is Meg 😊
                    </QA>
                </Question>
                <Question title="Radio Silence?">
                    <QA name="Q">
                        I messaged you and you still haven't got back to me, what gives?
                    </QA>
                    <QA name="A">
                        So you know that one really good friend that's not always the quickest 
                        to text you back, but is always there for you when you reeeeally need them? 
                        We're kinda like that friend, but with way better Poke recommendations.
                    </QA>
                </Question>
                <Question title="How do I get my Poké Bar reviewed?">
                    <QA name="Q">
                        How do I get my Poké Bar reviewed?
                    </QA>
                    <QA name="A">
                        <p>
                            Reach out to us! Head on over to the 
                            <Link to="/contact"
                                className=" font-bold hover:text-orange"
                            > Contact Us </Link>
                            page for more info. 
                            <br/>
                            <br/>
                            p.s. Gift cards are appreciated, but not required.
                            <br/>
                            p.p.s. We will still be brutally yet endearingly honest regardless of bribery.
                        </p>
                    </QA>
                </Question>
            </div>
        </Layout>
    )
};

export default FaqPage